import { FunctionComponent, useContext } from "react";
import {
  ScreenContext,
  ScreenContextInterface,
} from "../../Providers/ScreenContextProvider";
import Button from "../Button";

const ErrorPage: FunctionComponent<{ resetError: () => void }> = ({
  resetError,
}) => {
  const { setSplashScreen } = useContext(
    ScreenContext
  ) as ScreenContextInterface;

  const buttonFunc = () => {
    //   return to main screen
    setSplashScreen();
    // error needs to be wiped off of erroboundary state
    // or ErrorPage will be perpetually displayed
    resetError();
  };

  return (
    <>
      <h1 className="errorpage__heading">
        Unfortunately the app had an error.
      </h1>
      <p className="errorpage__text">Please click below to restart.</p>
      <Button
        onClick={buttonFunc}
        onBlur={buttonFunc}
        content="Restart"
        otherClassNames="errorpage__reloadbutton"
      />
    </>
  );
};

export default ErrorPage;
