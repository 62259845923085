import { FunctionComponent } from "react";

const GamePageCardNumer: FunctionComponent<{
  currentCardIdx: number;
  totalCards: number;
}> = ({ currentCardIdx, totalCards }) => {
  return (
    <p className="gamepage__cardnumber">
      {currentCardIdx + 1} of {totalCards}
    </p>
  );
};

export default GamePageCardNumer;
