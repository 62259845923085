import { FunctionComponent } from "react";

const LoadingPage: FunctionComponent = () => {
  return (
    <>
      {/* this is a target for a css animated loader see style.css */}
      <div className="loadingpage__kineticloader"></div>
      <p className="loadingpage__text">Loading...</p>
    </>
  );
};

export default LoadingPage;
