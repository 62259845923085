import { FunctionComponent, useContext } from "react";
import {
  CardsContext,
  CardsContextInterface,
} from "../../Providers/CardsContextProvider";
import Button from "../Button";

const SplashPage: FunctionComponent = () => {
  const { startNewGame } = useContext(CardsContext) as CardsContextInterface;
  return (
    <>
      <h1 className="splashpage__heading">Trivia Challenge!</h1>
      <p className="splashpage__instruction">Choose True or False!</p>
      <Button
        onClick={startNewGame}
        onBlur={startNewGame}
        content={"BEGIN"}
        otherClassNames="splashpage__startbutton"
      />
    </>
  );
};

export default SplashPage;
