import { FunctionComponent } from "react";

const ResultsPageHeading: FunctionComponent<{
  correctAnswers: number;
  totalCards: number;
}> = ({ correctAnswers, totalCards }) => {
  return (
    <h1 className="resultspage__heading">
      You scored
      <br />
      {`${correctAnswers} / ${totalCards}`}
    </h1>
  );
};

export default ResultsPageHeading;
