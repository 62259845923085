import { Component, ErrorInfo, ReactNode } from "react";
import ErrorPage from "./ErrorPage";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // this can receive argument error of type Error
  // but we dont use it in this case so it is 0-arity here
  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  //this resets the error state when the user clicks restart in the ErrorPage
  // otherwise the app will always present the ErrorPage as hasError:true persists in state
  private resetError: () => void = () => {
    this.setState({ hasError: false });
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    //   this could be logged to some service in production
    console.error("ErrorBoundary caught the following error", error, errorInfo);
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorPage resetError={this.resetError} />;
    }

    // when no error simply return the app
    return this.props.children;
  }
}

export default ErrorBoundary;
