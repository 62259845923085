import { FunctionComponent, useContext } from "react";
import {
  CardsContext,
  CardsContextInterface,
} from "../../Providers/CardsContextProvider";
import {
  ScreenContext,
  ScreenContextInterface,
} from "../../Providers/ScreenContextProvider";
import Button from "../Button";
import ResultsPageCard from "./ResultsPageCard";
import ResultsPageHeading from "./ResultsPageHeading";

const ResultsPage: FunctionComponent = () => {
  const { setSplashScreen } = useContext(
    ScreenContext
  ) as ScreenContextInterface;
  const { cardsList } = useContext(CardsContext) as CardsContextInterface;

  // cardslist initialises on the CardProvider with an empty Array of type Card[]
  // the user should never reach this page without cards loaded in because
  // previous pages are only loaded in after an API call
  // however for safety's sake this component will throw an error rather than show broken UI
  if (cardsList.length === 0) {
    throw new Error(
      "ResultsPage component has somehow received an empty array of Card[] - check for bugs in CardProvider component first"
    );
  }

  const totalCorrect: number = cardsList.filter(
    (card) => card.answer === card.chosenAnswer
  ).length;

  const numCards: number = cardsList.length;

  return (
    <>
      <ResultsPageHeading correctAnswers={totalCorrect} totalCards={numCards} />
      <ul className="resultspage__resultslist">
        {/* display each card dynamically */}
        {cardsList.map(({ question, answer, chosenAnswer }, i) => (
          <ResultsPageCard
            key={i}
            question={question}
            answer={answer}
            // this is boolean | undefined before the question is answered but should never be undefined at this results page
            chosenAnswer={chosenAnswer as boolean}
          />
        ))}
      </ul>
      <Button
        onClick={setSplashScreen}
        onBlur={setSplashScreen}
        otherClassNames="resultspage__replaybutton"
        content="REPLAY"
      />
    </>
  );
};

export default ResultsPage;
