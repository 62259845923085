import { FocusEventHandler, FunctionComponent, MouseEventHandler } from "react";

// in the GamePageButtonGroup the ability to disble the button after choice is part of UI to indicate user choice; see css of .gamepage__button and its subsequent state modifier css classes for styling details
// no other buttons in app will be disabled
const Button: FunctionComponent<{
  onClick: MouseEventHandler<HTMLButtonElement>;
  onBlur: FocusEventHandler<HTMLButtonElement>;
  otherClassNames?: string;
  content: string;
  disabled?: boolean;
}> = ({ onClick, onBlur, otherClassNames, content, disabled }) => {
  return (
    <button
      className={`btn ${otherClassNames ? otherClassNames : ""}`}
      onClick={onClick}
      onBlur={onBlur}
      //   undefined is falsy so if disabled prop is not given to Button button will simply not be disabled
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export default Button;
