import { FunctionComponent, useContext, useState } from "react";
import {
  ScreenContext,
  ScreenContextInterface,
} from "../../Providers/ScreenContextProvider";
import Button from "../Button";
import GamePageButtonGroup from "./GamePageButtonGroup";
import GamePageCard from "./GamePageCard";
import GamePageCardNumer from "./GamePageCardNumber";

import {
  Card,
  CardsContext,
  CardsContextInterface,
} from "../../Providers/CardsContextProvider";

const GamePage: FunctionComponent = () => {
  const { setResultsScreen } = useContext(
    ScreenContext
  ) as ScreenContextInterface;
  const [currentCardIdx, setCurrentCardIdx] = useState<number>(0);
  const { cardsList, chooseFalse, chooseTrue } = useContext(
    CardsContext
  ) as CardsContextInterface;
  // cardslist initialises on the CardProvider with an empty Array of type Card[] and only loads in a filled array on api return
  // the user should never reach this page without cards loaded in because
  // previous pages are only loaded in after an API call
  // however for safety's sake this component will throw an error rather than show broken UI
  if (cardsList.length === 0) {
    throw new Error(
      "GamePage component has somehow received an empty array of Card[] - check for bugs in CardProvider component first"
    );
  }

  const totalCards: number = cardsList.length;

  // if the error above wasnt implemented
  // an undefined cardsList[currentCardIdx] here would throw a much more opaque error and crash the app
  const { category, question, answer, chosenAnswer, hasBeenAnswered }: Card =
    cardsList[currentCardIdx];

  console.log(cardsList);

  const incrementCard = () => {
    // in this case all cards have been answered and it is time to see the results
    if (currentCardIdx + 1 === totalCards) {
      setResultsScreen();
    }
    // otherwise advance the game to the next card
    setCurrentCardIdx(currentCardIdx + 1);
  };

  return (
    <>
      <h1 className="gamepage__heading">{category}</h1>
      <GamePageCard
        question={question}
        answer={answer}
        chosenAnswer={chosenAnswer}
        hasBeenAnswered={hasBeenAnswered}
      />
      <GamePageButtonGroup
        answerTrue={() => {
          chooseTrue(currentCardIdx);
        }}
        answerFalse={() => {
          chooseFalse(currentCardIdx);
        }}
        chosenAnswer={chosenAnswer}
        hasBeenAnswered={hasBeenAnswered}
      />
      {hasBeenAnswered ? (
        <Button
          onBlur={incrementCard}
          onClick={incrementCard}
          content={currentCardIdx + 1 === totalCards ? "Results" : "Next"}
          otherClassNames="gamepage__nextbutton"
        />
      ) : (
        <GamePageCardNumer
          currentCardIdx={currentCardIdx}
          totalCards={totalCards}
        />
      )}
    </>
  );
};

export default GamePage;
