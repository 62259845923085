import { FunctionComponent } from "react";
import Button from "../Button";

const GamePageButtonGroup: FunctionComponent<{
  answerTrue: () => void;
  answerFalse: () => void;
  chosenAnswer?: boolean;
  hasBeenAnswered: boolean;
}> = ({ answerTrue, answerFalse, chosenAnswer, hasBeenAnswered }) => {
  return (
    <div className="gamepage__buttonscontainer">
      {/* True Button */}
      <Button
        onClick={answerTrue}
        onBlur={answerTrue}
        content={"True"}
        // change button state to indicate answer selection
        // see style.css for states
        otherClassNames={`gamepage__button ${
          hasBeenAnswered
            ? chosenAnswer === true
              ? "gamepage__button--chosen"
              : "gamepage__button--notchosen"
            : ""
        }`}
        disabled={hasBeenAnswered}
      />

      {/* False Button */}
      <Button
        onClick={answerFalse}
        onBlur={answerFalse}
        content={"False"}
        // change button state to indicate answer selection
        // see style.css for states
        otherClassNames={`gamepage__button ${
          hasBeenAnswered
            ? chosenAnswer === false
              ? "gamepage__button--chosen"
              : "gamepage__button--notchosen"
            : ""
        }`}
        disabled={hasBeenAnswered}
      />
    </div>
  );
};

export default GamePageButtonGroup;
