import { StrictMode } from "react";
import { render } from "react-dom";
import App from "./components/App";
import CardsContextProvider from "./Providers/CardsContextProvider";
import ScreenContextProvider from "./Providers/ScreenContextProvider";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

try {
  render(
    <StrictMode>
      <ScreenContextProvider>
        <ErrorBoundary>
          <CardsContextProvider>
            <App />
          </CardsContextProvider>
        </ErrorBoundary>
      </ScreenContextProvider>
    </StrictMode>,
    document.getElementById("root")
  );
} catch (error) {
  console.error(error);
}
