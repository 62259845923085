import { FunctionComponent, useContext } from "react";
import SplashPage from "./SplashPage/SplashPage";

import "../../static/style.css";
import LoadingPage from "./LoadingPage/LoadingPage";
import GamePage from "./GamePage/GamePage";
import ResultsPage from "./ResultsPage/ResultsPage";
import {
  ScreenContext,
  ScreenContextInterface,
  ScreenType,
} from "../Providers/ScreenContextProvider";

// together with ScreenContextProvider
// this forms the fundamental state machine that shows the screen state of the entire app
const switchPage = (screen: ScreenType): JSX.Element => {
  const screens = {
    splash: <SplashPage />,
    loading: <LoadingPage />,
    game: <GamePage />,
    results: <ResultsPage />,
  };

  return screens[screen];
};

const App: FunctionComponent = () => {
  // because ScreenContext is ScreenContextInterface | null we use typecasting to ensure it is not null
  const { screenState }: { screenState: ScreenType } = useContext(
    ScreenContext
  ) as ScreenContextInterface;

  return switchPage(screenState);
};
export default App;
