import { createContext, useState, FunctionComponent } from "react";

// This module defines a state machine to switch between the four main screens/views of the app
// The app has four main views, defined in ScreenType below
// This context provider provides to the lower nodes botht he current screenState and
// four specific setter functions to move to other fundamental screen states

// type Screen exists in typescript namespace already, relating to the window object
// name ScreenType is to avoid any namespace confusion
//
// This type encompasses all possible screen states of the game
export type ScreenType = "splash" | "loading" | "game" | "results";

// we export this to typecast in context receiving components
export interface ScreenContextInterface {
  screenState: ScreenType;
  // these type signatures go to void because
  // these will be side-effect functions that set the state
  // see their definitions below
  setSplashScreen: () => void;
  setLoadingScreen: () => void;
  setGameScreen: () => void;
  setResultsScreen: () => void;
}

// this needs to be initialised with a default null argument for typechecking
// its real value is set in the return of the below functional component
// then we typecast to ScreenContextInterface in any receiving component
export const ScreenContext = createContext<ScreenContextInterface | null>(null);

const ScreenContextProvider: FunctionComponent = ({ children }) => {
  const [screenState, setScreenState] = useState<ScreenType>("splash");

  const currentScreenContext: ScreenContextInterface = {
    screenState: screenState,
    // the preference is to set the state indirectly with precise functions, rather than exposing the full power to set any higher state to lower components
    setSplashScreen: () => setScreenState("splash"),
    setLoadingScreen: () => setScreenState("loading"),
    setGameScreen: () => setScreenState("game"),
    setResultsScreen: () => setScreenState("results"),
  };

  return (
    <ScreenContext.Provider value={currentScreenContext}>
      {children}
    </ScreenContext.Provider>
  );
};

export default ScreenContextProvider;
