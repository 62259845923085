import { FunctionComponent } from "react";

const GamePageCard: FunctionComponent<{
  question: string;
  answer: boolean;
  chosenAnswer?: boolean;
  hasBeenAnswered: boolean;
}> = ({ question, answer, chosenAnswer, hasBeenAnswered }) => {
  return (
    <div className="gamepage__card">
      <p className="gamepage__cardtext">
        {/* if the question has been answered, display tyhe result as correct or incorrect */}
        {hasBeenAnswered
          ? chosenAnswer === answer
            ? `Correct! This statement was ${String(answer)}.`
            : `Wrong! This statement was ${String(answer)}.`
          : // if the question hasnt been answered just display the question
            question}
      </p>
    </div>
  );
};

export default GamePageCard;
