import { createContext, useState, FunctionComponent, useContext } from "react";
import { decode } from "html-entities";
import { ScreenContext, ScreenContextInterface } from "./ScreenContextProvider";

// this is the question as it is structured in the API
interface Question {
  category: string;
  type: string;
  difficulty: string;
  question: string;
  correct_answer: string;
  incorrect_answers: string[];
}

// the given api endpoint return a response code and then a list of questions
interface APIData {
  response_code: number;
  results: Question[];
}

// Card is the Question restructured for the needs of the application
// category and question remain the same type
// answer is boolean as we are guarenteed to be working from the true-false subset of the tivia DBs questions
// chosenAnswer indicates the answer the user has selected in the game, true or false - it is undefined until the user chooses an answer
//
// because the chosenAnswer is possibly undefined AND is a boolean, checking if it exists is complex
// using an explicit hasBeenAnswered is ultimately simpler
export interface Card {
  category: string;
  question: string;
  answer: boolean;
  hasBeenAnswered: boolean;
  chosenAnswer?: boolean;
}

// this takes in type Question and returns type Card
// it is preferrable to handle ALL data transformation in this provider rather than in any leaf component
// this approach gives us a single source of truth for data structure, and
// allows us to build our components assuming the ideal structure for our app design
// rather than bending our app in every component to fit whatever data the api provides
const questionToCard = ({
  category,
  question,
  correct_answer,
}: Question): Card => ({
  category: category,
  // decode deals with html entities in API question db, chiefly &quot; and &nbsp; see imports above for library
  question: decode(question),
  answer: correct_answer === "True",
  hasBeenAnswered: false,
  // note chosenAnswer is left undefined until the user chooses an answer
});

// we export this to typecast in context receiving components
export interface CardsContextInterface {
  cardsList: Card[];
  // these type signatures go to void because
  // these will be side-effect functions that set the state
  // see their definitions below
  chooseTrue: (idx: number) => void;
  chooseFalse: (idx: number) => void;
  startNewGame: () => void;
}

// this needs to be initialised with a default null argument for typechecking
// its real value is set in the return of the below functional component
// then we typecast to CardsContextInterface in any receiving component
export const CardsContext = createContext<CardsContextInterface | null>(null);

// FunctionComponent has children as a default prop, doesnt need to be declared in an interface
const CardsContextProvider: FunctionComponent = ({ children }) => {
  // we initialise it as an empty value because it will receive the card from the api on loading of begin state;
  // if the user ends up at the game page or the results page with this state then the game cards will be an empty array
  // both of those components defensively throw a relevant error in that case which is caught by ErrorBoundary
  const [cardsList, setCardsList] = useState<Card[]>([] as Card[]);

  const { setLoadingScreen, setGameScreen } = useContext(
    ScreenContext
  ) as ScreenContextInterface;

  const currentCardsContext: CardsContextInterface = {
    cardsList: cardsList,
    // the preference is to set the state indirectly with precise functions, rather than exposing the full power to set any higher state to lower components
    chooseTrue: (idx) =>
      setCardsList(
        // this immutably updates the chosen card to have been answered True
        cardsList
          .slice(0, idx)
          .concat({
            ...cardsList[idx],
            hasBeenAnswered: true,
            chosenAnswer: true,
          })
          .concat(cardsList.slice(idx + 1))
      ),
    chooseFalse: (idx) =>
      setCardsList(
        // this immutably updates the chosen card to have been answered False
        cardsList
          .slice(0, idx)
          .concat({
            ...cardsList[idx],
            hasBeenAnswered: true,
            chosenAnswer: false,
          })
          .concat(cardsList.slice(idx + 1))
      ),
    startNewGame: () => {
      setLoadingScreen();
      fetch(
        `https://opentdb.com/api.php?amount=10&difficulty=hard&type=boolean`
      )
        .then((res) => res.json())
        .then(({ results }: APIData): Card[] => results.map(questionToCard))
        .then(setCardsList)
        // ensures that gamescreen only loads once cardslist has been set, avoiding ever throwing the empty cardslist error
        .then(setGameScreen)
        // react error boundaries are not designed to handle asynchronous errors in promises well so this needs to be handled on its own here
        .catch((err) =>
          // this could be logged to some service in a production application
          console.error(
            "Error in api call, check CardsContextProvider for bugs first, error message is:",
            err
          )
        );
    },
  };
  return (
    <CardsContext.Provider value={currentCardsContext}>
      {children}
    </CardsContext.Provider>
  );
};

export default CardsContextProvider;
