import { FunctionComponent } from "react";

const ResultsPageCard: FunctionComponent<{
  question: string;
  answer: boolean;
  chosenAnswer: boolean;
}> = ({ question, answer, chosenAnswer }) => {
  return (
    <li
      className={`resultspage__resultsitem ${
        answer === chosenAnswer ? "resultspage__resultsitem--correct" : ""
      }`}
    >
      <div className="resultspage__texticon">{answer ? "T" : "F"}</div>
      <p className="resultspage__cardtext">{question}</p>
    </li>
  );
};

export default ResultsPageCard;
